// jshint esversion: 6

const PIKE = {};


(function() {
  'use strict';

  const body = document.body;
  // optimise resize event with requestAnimationFrame
  PIKE.optimizedResize = (() => {
    let callbacks = [],
      running = false;
    // fired on resize event
    const resize = () => {
      if (!running) {
        running = true;
        window.requestAnimationFrame(runCallbacks);
      }
    };
    // run the actual callbacks
    const runCallbacks = () => {
      callbacks.forEach(callback => {
        callback();
      });
      running = false;
    };
    // adds callback to loop
    const addCallback = callback => {
      if (callback) {
        callbacks.push(callback);
      }
    };
    return {
      // initalize resize event listener
      init(callback) {
        window.addEventListener('resize', resize);
        addCallback(callback);
      }
    };
  })();


  PIKE.isTouch = (() => {
    return /Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent || navigator.vendor || window.opera);
  })();

  (() => {
    if (PIKE.isTouch) {
      body.className += ' touch';
    }
  })();

  (() => {
    // This allows us to get the scrollbar width for the site.
    // Create the measurement node
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'scrollbar-measure';
    body.appendChild(scrollDiv);
    // Get the scrollbar width
    PIKE.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    // Delete the DIV
    body.removeChild(scrollDiv);

    const iOS = ( navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false );
    // add class to body if IOS
    if (iOS) {
      PIKE.iOS = true;
      body.className += ' iOS';
    }
    if (navigator.userAgent.match(/(iPad|iPhone);.*CPU.*OS 7_\d/i)) {
      body.className += ' iOS7';
    }
  })();


  PIKE.waitFor = (what, callback, freq=10) => {
    const f = function(what, callback, freq) {
      const c = function() {
        if (window[what] !== undefined) {
          callback();
          return;
        }
        setTimeout(c, freq);
      };

      c();
    };

    new f(what, callback, freq);
  };

})();

